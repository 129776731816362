import React from "react";
import HelpPage from "../../components/helpPage";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import { graphql } from "gatsby";
import Img from "gatsby-image";

export const query = graphql`
  query {
    zdjecie1: file(
      relativePath: { eq: "Prosty-system-CRM-lista-aktywnosci.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(
      relativePath: {
        eq: "Prosty-system-CRM-dodawanie-zadan-i-zdarzen-na-liscie-aktywnosci.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const ListaAktywności = ({ data }) => {
  return (
    <HelpPage
      name="Lista aktywności"
      metaTitle="Pomoc - lista aktywności w prostym systemie CRM - QuickCRM"
      metaDescription="Lista aktywności w prostym systemie CRM - pomoc • Jak obsługiwać listę zrealizowanych i zaplanowanych działań? • Wypróbuj QuickCRM i sprzedawaj więcej, pierwsze 30 dni bezpłatnie!"
    >
      <div>
        <p>
          <strong>Lista aktywności</strong> to miejsce, w którym wyświetlane są
          zadania i zdarzenia wszystkich pracowników.
        </p>
        <p>
          Jeśli chcesz wyświetlić zadania konkretnej osoby, skorzystaj z
          zaawansowanej wyszukiwarki. Pamiętaj, że filtry możesz ze sobą łączyć
          i np. wyświetlić wyłącznie swoje zadania o konkretnym typie, np.
          planowane spotkania.
        </p>
      </div>
      <Tabs defaultTab="zakladka1">
        <TabList>
          <Tab tabFor="zakladka1">[1] Wyszukiwanie zadań</Tab>
          <Tab tabFor="zakladka2">[2] Dodawanie zadań i zdarzeń</Tab>
        </TabList>
        <TabPanel tabId="zakladka1">
          <p>
            <strong>Wyszukiwanie zadań i zdarzeń</strong>
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="lista aktywności"
            fluid={data.zdjecie1.childImageSharp.fluid}
          />

          <p>[1] Zaznacz, jaki typ aktywności chcesz wyszukać.</p>
          <p>[2] Wybierz z listy typ aktywności.</p>
          <p>[3] Wyszukuj zadania lub zdarzenia konkretnych pracowników.</p>
          <p>[4] Wyszukiwanie wiadomości cyklicznych.</p>
          <p>
            Filtry możesz łączyć ze sobą, aby szybko docierać do ważnych
            informacji. Możesz wyszukiwać wiadomości również po dacie, nazwie
            lub opisie.
          </p>
        </TabPanel>
        <TabPanel tabId="zakladka2">
          <p>
            <strong>Tworzenie zadań i zdarzeń z widoku listy aktywności</strong>
          </p>
          <Img
            style={{
              width: 1100,
              margin: "0 auto",
              maxWidth: "100%"
            }}
            alt="dodawanie zadań i zdarzeń"
            fluid={data.zdjecie2.childImageSharp.fluid}
          />
          <p>
            [1] Tworzenie zadania z widoku listy aktywności. Atrybuty zadań są
            identyczne, jak w przypadku tworzenia zadania w kalendarzu.
          </p>
          <p>
            [2] Tworzenie zdarzenia z widoku listy aktywności. Atrybuty zdarzeń
            są identyczne, jak w przypadku tworzenia zdarzenia w kalendarzu.
          </p>
        </TabPanel>
      </Tabs>
    </HelpPage>
  );
};

export default ListaAktywności;
